// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

$spark-palette: (
  50 : #ffeee1,
  100 : #ffd4b3,
  200 : #ffb881,
  300 : #ff9b4e,
  400 : #ff8528,
  500 : #ff7002,
  600 : #ff6802,
  700 : #ff5d01,
  800 : #ff5301,
  900 : #ff4101,
  A100 : #ffffff,
  A200 : #fff5f2,
  A400 : #ffccbf,
  A700 : #ffb7a6,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: white,
    600: white, 
    700: white, 
    800: white,
    900: white,
    A100 : rgba(black, 0.87),
    A200 : rgba(black, 0.87),
    A400 : rgba(black, 0.87),
    A700 : rgba(black, 0.87),
  )
 );

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$spark-frontend-primary: mat.define-palette($spark-palette);
$spark-frontend-accent: mat.define-palette($spark-palette, 200, 100, 400);

// // The warn palette is optional (defaults to red).
// $spark-frontend-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$spark-frontend-theme: mat.define-light-theme((
  color: (
    primary: $spark-frontend-primary,
    accent: $spark-frontend-accent,
    // warn: $spark-frontend-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($spark-frontend-theme);

/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }

body { 
  margin: 0; 
  font-family: "Inter Tight", sans-serif;
  --text-color: #101010;
  --gray: rgba(0, 0, 0, 0.3);
  --background-color: whitesmoke; //white; 
  --chat-bubble-bgcolor: rgb(236, 236, 236);
  --chat-button-color: #fb8328;
  --chat-input-container-bgcolor: rgb(230, 230, 230);
  --darker-bgcolor: #eee;
  --lighter-bgcolor: rgba(251, 251, 251, 1);
  --opts-avatar-bgcolor: white; // var(--background-color);
  --opts-avatar-color: #ff7002;
  --card-hover-bgcolor: rgba(252, 237, 223, 1);
  
  --mat-expansion-header-collapsed-state-height:56px;

  // customitza els inputs de l'Angular Material
  // --mdc-filled-text-field-container-color:var(--lighter-bgcolor);
  // --mdc-filled-text-field-input-text-color:var(--text-color);
  // --mdc-filled-text-field-input-text-placeholder-color:var(--gray);
  // --mat-form-field-container-text-size:18px;
  --mdc-outlined-text-field-outline-color:rgba(222, 229, 235, 1);
  --mdc-outlined-text-field-container-shape:5px;
}

.leaky {
  overflow: visible!important;
  --mat-menu-container-shape: 20px; // <- el border radius del menú;
}
.pointyDialog::after {
  z-index: 10;
  position: absolute;
  top: var(--point-top, 676px); 
  left: var(--point-left, 974px);
  display: block;
  width: 15px;
  height: 15px;
  background-color: var(--point-bgcolor, white);
  transform: translate(-300%, -50%) rotate(45deg);
  content: "";
}
.pointyDialog::before {
  z-index: 10;
  position: absolute;
  top: var(--avatar-top, 76px); 
  left: var(--avatar-left, 554px);
  display: block;
  transform: translate(-50%, 12.5%);
  background:var(--avatar-bg, #eee); 
  border-radius:50%;
  border:solid 3px white;
  width: 47px;
  height: 47px;
  content: "";
}
